
import { defineComponent, onMounted, reactive, toRefs } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { wuRequest } from '@/service'
import { url } from '@/service/config'

//import HelloWorld from '@/components/HelloWorld.vue' // @ is an alias to /src

export default defineComponent({
    components: {},
    setup() {
        let t = reactive<any>({
            data: {},
            workActive: true,
            url
        })
        const route = useRoute().query
        let router = useRouter()
        onMounted(async () => {
            let query = route.type

            // 保持菜单更新一直在顶
            document.body.scrollTop = document.documentElement.scrollTop = 0
            query === '办公椅' ? (t.workActive = true) : (t.workActive = false)
            let res = await wuRequest.get({
                url: '/production-list?type=' + query
            })

            if (res.data.code === 10001) {
                t.data = res.data.data.data
            }
        })
        const changMenu = async (name: string) => {
            name === '办公椅' ? (t.workActive = true) : (t.workActive = false)
            let res = await wuRequest.get({
                url: '/production-list?type=' + name
            })
            if (res.data.code === 10001) {
                t.data = res.data.data.data
            }
        }
        const linkToDesc = (id: number) => {
            router.push('/production/' + id)
        }
        return { ...toRefs(t), changMenu, linkToDesc }
    }
})
